import React from "react";
import { Link } from "react-router-dom";
import { useFirebaseAuth } from "../../context/FirebaseAuthContext";

import "../../assets/styles/header.scss";
import HeaderNav from "./HeaderNav";

export default function Header() {
	const { currentUser } = useFirebaseAuth();

	return (
		<>
			<header className="header">
				<Link to={"/"} className="header__logo">
					<img src={"/assets/images/logo/logo.png"} alt="Logo" className="header__logo-image" />
					<h1 className="header__logo-text">{process.env.REACT_APP_PROJECT_NAME}</h1>
				</Link>
				<nav className="header__navigation">
					<HeaderNav />
				</nav>
				<div className="header__right">
					<div className="header__social">
						<a
							href="https://instagram.com/"
							target="_blank"
							rel="noreferrer"
							className="header__social__link"
						>
							<img src="/assets/icons/instagram.svg" alt="Instagram" className="header__social-icon" />
						</a>
						<a
							href="https://telegram.org/"
							target="_blank"
							rel="noreferrer"
							className="header__social__link"
						>
							<img src="/assets/icons/telegram.svg" alt="Telegram" className="header__social-icon" />
						</a>
						<a href="https://discord.gg/" target="_blank" rel="noreferrer" className="header__social__link">
							<img src="/assets/icons/discord.svg" alt="Discord" className="header__social-icon" />
						</a>
					</div>
					<Link to={currentUser ? "/profile" : "/auth"} className="header__user">
						<img src="/assets/icons/user.svg" alt="Profile" className="header__user-icon" />
					</Link>
				</div>
			</header>
			<nav className="header__navigation-mobile">
				<HeaderNav />
			</nav>
		</>
	);
}
