import React from "react";
import { Link } from "react-router-dom";

export default function HeaderNav() {
	return (
		<>
			<Link to={"/"} className="header__navigation-link">
				Basty bet
			</Link>
			<Link to={"/about"} className="header__navigation-link">
				Joba týraly
			</Link>
			<Link to={"/titles"} className="header__navigation-link">
				Shyǵarmalar
			</Link>
		</>
	);
}
