import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFirebaseAuth } from "../../../context/FirebaseAuthContext";
import { useFirebaseFireStore } from "../../../context/FirebaseFireStoreContext";

import banner from "../../../assets/images/bg-image.jpg";

import "../../../assets/styles/profile.scss";

export default function ProfileContent() {
	const { currentUser, logOut } = useFirebaseAuth();
	const { getUserData } = useFirebaseFireStore();
	const [userAllData, setUserData] = useState();

	const redirect = useNavigate();

	useEffect(() => {
		return async () => {
			setUserData(await getUserData({ email: currentUser.email }));
		};
	}, []);

	if (userAllData) {
		const userData = userAllData.data();
		return (
			<section className="profile">
				<img
					src={userData.bannerUrl !== "" ? userData.bannerUrl : banner}
					alt="banner"
					className="profile__banner-image"
				/>
				<div className="profile__container">
					<p className="profile__heading">Profıl</p>
					<div className="profile__info">
						<div className="profile__user">
							<div className="profile__status">
								<img
									src={
										userData.profileUrl !== ""
											? userData.profileUrl
											: "https://placekitten.com/200/200"
									}
									onError={(event: SyntheticEvent<HTMLImageElement>) => {
										event.target.src = "https://placekitten.com/200/200";
									}}
									alt="User"
									className="profile__user-image"
								/>
								<p className="profile__status-text">
									Status:{" "}
									{userData.status ? userData.status : `${process.env.REACT_APP_PROJECT_NAME} user`}
								</p>
							</div>
							<ul className="profile__info-list">
								<li className="profile__item">{userData.name}</li>
								<li className="profile__item">{userData.login}</li>
								<li>
									<Link to={"/profile/settings"} className="profile__item">
										Parametrler
									</Link>
								</li>
								<li
									className="profile__item"
									style={{ cursor: "pointer", marginTop: "auto" }}
									onClick={() => {
										logOut();
										redirect("/");
									}}
								>
									Log Out
								</li>
							</ul>
						</div>
						{/*<div className="profile__actions">*/}
						{/*	<img src="/assets/icons/edit.svg" alt="login-edit" className="profile__action" />*/}
						{/*	<img src="/assets/icons/share.svg" alt="banner-edit" className="profile__action" />*/}
						{/*</div>*/}
					</div>
				</div>
			</section>
		);
	}
}
