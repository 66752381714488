import React, { useEffect, useRef } from "react";
import { useTitles } from "../../../context/TitlesContext";
import { Link, useNavigate, useParams } from "react-router-dom";

import "../../../assets/styles/chapter.scss";

export default function ChapterContent() {
	const selectRef = useRef(null);
	const { titlesData } = useTitles();
	const navigate = useNavigate();
	const urlTitleName = useParams().title.toLowerCase();
	const urlChapterNumber = parseInt(useParams().chapter);

	let titleIndex = -1;
	let currentTitle = {};

	titlesData.forEach((title, key) => {
		if (title.titleName.split(" ").join("-").toLowerCase() === urlTitleName) {
			titleIndex = key;
			currentTitle = titlesData[titleIndex];
		}
	});

	let fix = [];

	for (let i = 1; i <= currentTitle.chapters[urlChapterNumber - 1]; i++) {
		fix.push(i);
	}

	useEffect(() => {
		if (titleIndex === -1 || !currentTitle.chapters[urlChapterNumber - 1]) {
			navigate("/titles");
		}
	}, []);

	return (
		<section className="chapter">
			<select
				name="image"
				ref={selectRef}
				className="chapter__selection"
				onChange={() => {
					window.location = `#${selectRef.current.value}`;
				}}
			>
				{fix.map((image, key) => {
					return (
						<option value={image} className="chapter__option" key={key}>
							{image} bet
						</option>
					);
				})}
			</select>
			<div className="chapter__container">
				{fix.map((image, key) => {
					return (
						<img
							src={`/titles/${urlTitleName}/chapter-${urlChapterNumber}/${image}.webp`}
							alt={`${image}/${currentTitle.chapters[urlChapterNumber - 1]}`}
							key={key}
							id={key + 1}
							className="chapter__image"
							loading={"lazy"}
							draggable={false}
						/>
					);
				})}

				<div className="chapter__navigation">
					<Link to={`/title/${urlTitleName}/chapter/${urlChapterNumber - 1}`} className="chapter__button">
						Previous
					</Link>
					<Link to={`/title/${urlTitleName}/chapter/${urlChapterNumber + 1}`} className="chapter__button">
						Next
					</Link>
				</div>
			</div>
		</section>
	);
}
