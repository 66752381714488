import React, { forwardRef } from "react";

interface SettingsInputBlockProps {
	title: string;
	type?: "password" | "text" | "button";
	buttonText?: string;
	buttonOnClickHandler?: (any) => void;
	buttonDisabled?: boolean;
}

const SettingsInputBlock = forwardRef((props: SettingsInputBlockProps, ref: React.RefObject<HTMLInputElement>) => {
	return (
		<form onSubmit={(e) => e.preventDefault()} className="settings__settings-block">
			<label className="settings__settings-parameter-text">{props.title}</label>

			{props.type === "button" ? (
				<button
					className="settings__settings-parameter-button"
					onClick={() => props.buttonOnClickHandler()}
					disabled={props.buttonDisabled}
				>
					{props.buttonText}
				</button>
			) : (
				<input
					type={props.type}
					ref={ref}
					className="settings__settings-parameter-input"
					placeholder={"Bir nárse..."}
					autoComplete={"off"}
				/>
			)}
		</form>
	);
});

export default SettingsInputBlock;
