import React, { useEffect } from "react";
import { useFirebaseAuth } from "../../context/FirebaseAuthContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { defaultAnimation } from "../../App";

import "../../assets/styles/settings.scss";

import Header from "../../components/header/Header";
import Hero from "../../components/Hero";
import SettingsContent from "./components/SettingsContent";

export default function Settings() {
	const { currentUser } = useFirebaseAuth();
	const redirect = useNavigate();

	useEffect(() => {
		if (!currentUser) {
			redirect("/");
		}
	}, [currentUser]);

	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero title={"Parametrler"} description={""} />
			<SettingsContent />
		</motion.div>
	);
}
