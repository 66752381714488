import React, { useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../api/Firebase";
import firebase from "firebase/compat/app";

interface AuthProps {
	email?: string;
	password?: string;
}
interface AuthContextProps {
	currentUser: firebase.User | null;
	signUp: (AuthProps) => Promise<firebase.auth.UserCredential>;
	signIn: (AuthProps) => Promise<firebase.auth.UserCredential>;
	logOut: () => Promise<void>;
	resetPassword: (AuthProps) => Promise<void>;
	verifyEmail: (AuthProps) => Promise<void>;
	updatePassword: (AuthProps) => Promise<void>;
}
const FirebaseAuthContext = React.createContext();

export function useFirebaseAuth(): AuthContextProps {
	return useContext(FirebaseAuthContext);
}

export function FirebaseAuthProvider({ children }: { children: React.ReactNode }) {
	const [currentUser, setCurrentUser] = useState();
	const [isLoading, setIsLoading] = useState(true);

	function signIn({ email, password }: AuthProps) {
		return firebaseAuth.signInWithEmailAndPassword(email, password);
	}
	function signUp({ email, password }: AuthProps) {
		return firebaseAuth.createUserWithEmailAndPassword(email, password);
	}

	function logOut() {
		return firebaseAuth.signOut();
	}

	function resetPassword({ email }: AuthProps) {
		return firebaseAuth.sendPasswordResetEmail(email);
	}
	function verifyEmail() {
		return firebaseAuth.currentUser.sendEmailVerification();
	}

	useEffect(() => {
		return firebaseAuth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setIsLoading(false);
		});
	}, []);

	const values = { currentUser, signIn, signUp, logOut, resetPassword, verifyEmail };

	return <FirebaseAuthContext.Provider value={values}>{!isLoading && children}</FirebaseAuthContext.Provider>;
}
