import React from "react";

interface TitlesContextProps {
	children: React.ReactNode;
}

const TitlesContext = React.createContext(null);

export function useTitles() {
	return React.useContext(TitlesContext);
}

export function TitlesProvider({ children }: TitlesContextProps) {
	const titlesData = [
		{
			titleName: "Death in another world",
			titleFullName: "Death in another world",
			author: "EraMangaka",
			translated: false,
			translatedBy: "",
			published: 2022,
			translatedDate: 0,
			chapters: [
				16, // chapter 1
				13, // chapter 2
				13, // chapter 3
				8, // chapter 4
				12, // chapter 5
				10, // chapter 6
				11, // chapter 7
			],
			genres: [""],
		},
		{
			titleName: "TokiDoki",
			titleFullName: "TokiDoki",
			author: "KOMI Naoshi",
			translated: true,
			translatedBy: "Kazlators",
			published: 2016,
			translatedDate: 2022,
			chapters: [
				48, // chapter 1
			],
			genres: [""],
		},
	];

	const values = { titlesData };

	return <TitlesContext.Provider value={values}>{children}</TitlesContext.Provider>;
}
