import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTitles } from "../../../context/TitlesContext";

export default function TitleContent() {
	const [chapterSearch, setChapterSearch] = useState("");
	let currentTitle = {};
	let titleIndex = -1;

	const { titlesData } = useTitles();
	const navigate = useNavigate();
	const urlTitleName = useParams().title.toLowerCase();

	titlesData.forEach((title, key) => {
		if (title.titleName.split(" ").join("-").toLowerCase() === urlTitleName) {
			titleIndex = key;
			currentTitle = titlesData[titleIndex];
		}
	});

	useEffect(() => {
		if (titleIndex === -1) {
			navigate("/titles");
		}
	}, []);

	return (
		<section className="title">
			<div className="title__container">
				<div className="title__info">
					<div className="title__image">
						<img src={`/titles/${urlTitleName}/cover.webp`} alt="Cover" className="title__cover" />
						<ul className="title__info-list">
							<li className="title__info-text">
								Manga ataýy: <span>{currentTitle.titleName}</span>
							</li>
							<li className="title__info-text">
								Tolyq aty: <span>{currentTitle.titleFullName}</span>
							</li>
							<li className="title__info-text">
								Avtor: <span>{currentTitle.author}</span>
							</li>
							<li className="title__info-text">
								Shyǵarylǵan kúni: <span>{currentTitle.published}</span>
							</li>
							<li className="title__info-text">
								Aýdarǵan: <span>{currentTitle.translated ? currentTitle.translatedBy : "-"}</span>
							</li>
							<li className="title__info-text">
								Aýdarylǵan:{" "}
								<span>{currentTitle.translatedDate !== 0 ? currentTitle.translatedDate : "-"}</span>
							</li>
							<Link to={"/title/" + urlTitleName + "/chapter/1"} className="title__info-text">
								Basynan oqý
							</Link>
						</ul>
					</div>
				</div>
				<div className="title__chapters-block">
					<div className="title__chapters-info">
						<h2 className="title__chapters-heading">Taraýlar tizimi</h2>
						<input
							type="text"
							value={chapterSearch}
							onChange={(e) => {
								setChapterSearch(e.target.value);
							}}
							disabled={true}
							placeholder={"Ýaqytsha jumys istemeıdi"}
							className="title__chapters-search"
						/>
						<img src="/assets/icons/search.svg" alt="search" className="title__chapters-search-icon" />
					</div>
					<nav className="title__chapters-list">
						{currentTitle.chapters
							// .filter((chapter, key) => {
							// 	if (chapterSearch === "") {
							// 		return chapter;
							// 	} else if (chapterSearch.split(" ").join("").toLowerCase().includes(key)) {
							// 		return chapter;
							// 	}
							// })
							.map((filteredChapter, key) => {
								return (
									<Link
										to={`/title/${urlTitleName}/chapter/${key + 1}`}
										key={key + 1}
										className="title__chapters-link"
									>
										Taraý {key + 1}
										<img
											src="/assets/icons/arrow-left.svg"
											alt="arrow-left"
											className="title__chapters-icon"
										/>
									</Link>
								);
							})}
					</nav>
				</div>
			</div>
		</section>
	);
}
