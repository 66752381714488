import React from "react";
import { motion } from "framer-motion";
import { defaultAnimation } from "../../App";

import "../../assets/styles/auth.scss";

import AuthForm from "./components/AuthForm";

export default function Auth() {
	return (
		<motion.div
			className="auth"
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<AuthForm />
			<span className="auth__right"></span>
		</motion.div>
	);
}
