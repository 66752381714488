import React from "react";
import { motion } from "framer-motion";

import Header from "../../components/header/Header";
import Hero from "../../components/Hero";
import TitlesContent from "./components/TitlesContent";
import { defaultAnimation } from "../../App";

export default function Titles() {
	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero title={"Mangalar"} description={"Keıin jazam"} />
			<TitlesContent />
		</motion.div>
	);
}
