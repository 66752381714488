import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTitles } from "../../../context/TitlesContext";

import "../../../assets/styles/titles.scss";

export default function TitlesContent() {
	const { titlesData } = useTitles();
	const [inputSearch, setInputSearch] = useState("");

	return (
		<section className="titles">
			<div className="titles__container">
				<div className="titles__general">
					<h2 className="titles__text">Tolyq tizimi</h2>
					<input
						type="text"
						className="titles__search"
						autoComplete={"off"}
						placeholder={"Search"}
						value={inputSearch}
						onChange={(e) => setInputSearch(e.target.value)}
					/>
				</div>
				<ul className="titles__list">
					{titlesData
						.filter((title) => {
							if (inputSearch === "") {
								return title;
							} else if (
								title.titleName
									.toLowerCase()
									.split(" ")
									.join("")
									.includes(inputSearch.toLowerCase().split(" ").join(""))
							) {
								return title;
							}
						})
						.map((filteredTitle, key) => {
							return (
								<li className="titles__item" key={key}>
									<img
										src={
											"/titles/" +
											filteredTitle.titleName.split(" ").join("-").toLowerCase() +
											"/cover.webp"
										}
										alt={`${key + 1} page`}
										className="titles__item-image"
										loading={"lazy"}
									/>
									<Link
										to={`/title/${filteredTitle.titleName.split(" ").join("-").toLowerCase()}/`}
										className="titles__item-text"
									>
										Ashyp qaraý
									</Link>
								</li>
							);
						})}
				</ul>
			</div>
		</section>
	);
}
