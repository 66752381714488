import React, { useContext } from "react";
import firestore, { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firebaseFireStore } from "../api/Firebase";

const FirebaseFireStoreContext = React.createContext();

interface FireStoreProps {
	email?: string;
	login?: string;
	name?: string;
	profileUrl?: string;
	bannerUrl?: string;
}

interface FireStoreContextProps {
	createUserInfo?: (FireStoreProps) => Promise<void>;
	getUserData?: (FireStoreProps) => Promise<firestore.DocumentSnapshot>;
	updateUserData?: (FireStoreProps) => Promise<void> | any;
}

export function useFirebaseFireStore(): FireStoreContextProps {
	return useContext(FirebaseFireStoreContext);
}

export function FirebaseFireStoreProvider({ children }: { children: React.ReactNode }) {
	const collectionPath = collection(firebaseFireStore, "users");
	async function createUserInfo({ email, login }: FireStoreProps) {
		await setDoc(doc(collectionPath, email), {
			login: login,
			email: email,
			translator: false,
			name: "...",
			profileUrl: "",
			bannerUrl: "",
			status: "",
		});
	}

	async function getUserData({ email }: FireStoreProps) {
		return await getDoc(doc(collectionPath, email));
	}

	async function updateUserData({ email, name, login, status, profileUrl, bannerUrl }: FireStoreProps) {
		const dataToUpdate = Object.assign(
			{},
			{
				name: name,
				login: login,
				profileUrl: profileUrl,
				bannerUrl: bannerUrl,
				status: status,
			}
		);

		Object.keys(dataToUpdate).forEach((key) => {
			if (dataToUpdate[key] === undefined || dataToUpdate[key] === null || dataToUpdate[key] === "") {
				delete dataToUpdate[key];
			}
		});

		await updateDoc(doc(collectionPath, email), dataToUpdate);
	}

	const values = { createUserInfo, getUserData, updateUserData };
	return <FirebaseFireStoreContext.Provider value={values}>{children}</FirebaseFireStoreContext.Provider>;
}
