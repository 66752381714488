import React, { useState } from "react";

import "../assets/styles/hero.scss";
import { Link } from "react-router-dom";

interface HeroProps {
	title?: string;
	description?: string;
}

export default function Hero({ title, description }: HeroProps) {
	const [burger, setBurger] = useState("");
	const [sidebar, setSidebar] = useState("");

	return (
		<section className="hero">
			<div className="hero__content">
				<div
					className={"hero__burger" + burger}
					onClick={() => {
						burger === "" ? setBurger(" active-burger") : setBurger("");
						sidebar === "" ? setSidebar(" active-sidebar") : setSidebar("");
						document.documentElement.style.overflow =
							document.documentElement.style.overflow === "hidden" ? "auto" : "hidden";
					}}
				>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<h2 className="hero__title">{title}</h2>
				<div className="hero__deco" style={{ display: description ? "flex" : "none" }}>
					<span className="hero__deco-line"></span>
					<p className="hero__description">{description}</p>
					<span className="hero__deco-line"></span>
				</div>
			</div>
			<div className={"hero__sidebar" + sidebar}>
				<nav
					className="hero__sidebar-list"
					onClick={() => {
						burger === "" ? setBurger(" active-burger") : setBurger("");
						sidebar === "" ? setSidebar(" active-sidebar") : setSidebar("");
						document.documentElement.style.overflow = "auto";
					}}
				>
					<Link to={"/notifications"} className="hero__sidebar-list-item">
						Habarlamalar
					</Link>
					<Link to={"/my-list"} className="hero__sidebar-list-item">
						Meniń tizimim
					</Link>
					<Link to={"/authors"} className="hero__sidebar-list-item">
						Avtorlar
					</Link>
					<Link to={"/profile/settings"} className="hero__sidebar-list-item">
						Parametrler
					</Link>
				</nav>
			</div>
		</section>
	);
}
