import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { TitlesProvider } from "./context/TitlesContext";

import "./assets/styles/default.scss";

import Home from "./pages/Home/Home";
import Auth from "./pages/Auth/Auth";
import Titles from "./pages/Titles/Titles";
import Title from "./pages/Title/Title";
import Chapter from "./pages/Chapter/Chapter";
import { FirebaseAuthProvider } from "./context/FirebaseAuthContext";
import Profile from "./pages/Profile/Profile";
import { AnimatePresence } from "framer-motion";
import { FirebaseFireStoreProvider } from "./context/FirebaseFireStoreContext";
import Settings from "./pages/Settings/Settings";

export const defaultAnimation = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	exit: {
		x: "-100%",
	},
	transition: {
		duration: 0.5,
		ease: "easeIn",
	},
};

export default function App() {
	const location = useLocation();
	document.documentElement.style.overflow = "auto";

	return (
		<TitlesProvider>
			<FirebaseFireStoreProvider>
				<FirebaseAuthProvider>
					<AnimatePresence initial={false} mode={"wait"}>
						<Routes location={location} key={location.pathname}>
							<Route exact path={"/"} element={<Home />} />
							<Route path={"/auth"} element={<Auth />} />
							<Route path={"/titles"} element={<Titles />} />
							<Route path={"/title/:title"} element={<Title />} />
							<Route path={"/title/:title/chapter/:chapter"} element={<Chapter />} />
							<Route path={"/profile"} element={<Profile />} />
							<Route path={"/profile/settings"} element={<Settings />} />
						</Routes>
					</AnimatePresence>
				</FirebaseAuthProvider>
			</FirebaseFireStoreProvider>
		</TitlesProvider>
	);
}
