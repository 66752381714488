import React, { useRef } from "react";
import { useFirebaseAuth } from "../../../context/FirebaseAuthContext";
import { useFirebaseFireStore } from "../../../context/FirebaseFireStoreContext";
import { useNavigate } from "react-router-dom";

import SettingsInputBlock from "./SettingsInputBlock";

export default function SettingsContent() {
	const nameRef: React.RefObject<HTMLInputElement> = useRef();
	const loginRef: React.RefObject<HTMLInputElement> = useRef();
	const statusRef: React.RefObject<HTMLInputElement> = useRef();
	const profileUrlRef: React.RefObject<HTMLInputElement> = useRef();
	const bannerUrlRef: React.RefObject<HTMLInputElement> = useRef();

	const { currentUser, verifyEmail, resetPassword } = useFirebaseAuth();
	const { updateUserData } = useFirebaseFireStore();
	const redirect = useNavigate();

	async function handleUpdateUserData({ event }) {
		event.preventDefault();
		await updateUserData({
			email: currentUser.email,
			name: nameRef.current.value,
			login: loginRef.current.value,
			status: statusRef.current.value,
			profileUrl: profileUrlRef.current.value,
			bannerUrl: bannerUrlRef.current.value,
		});

		redirect("/profile");
	}

	return (
		<section className="settings">
			<div className="settings__container">
				<nav className="settings__setting-list">
					<a href={"#profile"} className="settings__setting-item">
						Profıl
					</a>
					<a href={"#privacy"} className="settings__setting-item">
						Qupıalylyq
					</a>

					<button
						className="settings__setting-item"
						onClick={(e) => {
							handleUpdateUserData({
								event: e,
							});
						}}
					>
						Jańartý
					</button>
				</nav>
				<div className="settings__settings">
					<div className="settings__settings-blocks" id={"profile"}>
						<h2 className="settings__settings-title">Profıl</h2>
						<SettingsInputBlock title={"Atyńyz:"} ref={nameRef} />
						<SettingsInputBlock title={"Login:"} ref={loginRef} />
						<SettingsInputBlock title={"Status:"} ref={statusRef} />
						<SettingsInputBlock title={"Profıl URL:"} ref={profileUrlRef} />
						<SettingsInputBlock title={"Banner Url:"} ref={bannerUrlRef} />
					</div>
					<div className="settings__settings-blocks" id={"privacy"}>
						<h2 className="settings__settings-title"> Qupıalylyq</h2>

						<SettingsInputBlock
							title={"Elektrondyq poshta kúıi:"}
							type={"button"}
							buttonText={"Rastaý"}
							buttonDisabled={currentUser.emailVerified}
							buttonOnClickHandler={() => verifyEmail({ user: currentUser })}
						/>
						<SettingsInputBlock
							title={"Qupıa sózdi jańartý: "}
							type={"button"}
							buttonText={"Jańartý"}
							buttonDisabled={false}
							buttonOnClickHandler={() => resetPassword({ email: currentUser.email })}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}
