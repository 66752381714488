import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFirebaseAuth } from "../../../context/FirebaseAuthContext";
import { useFirebaseFireStore } from "../../../context/FirebaseFireStoreContext";

export default function AuthForm() {
	const [isLogin, setIsLogin] = useState(false);
	const [rememberTick, setRememberTick] = useState(false);
	const [showPass, setShowPass] = useState(true);
	const [isReset, setIsReset] = useState(false);

	const [inputLogin, setInputLogin] = useState("");
	const [inputEmail, setInputEmail] = useState("");
	const [inputPassword, setInputPassword] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const { currentUser, signIn, signUp, resetPassword } = useFirebaseAuth();
	const { createUserInfo } = useFirebaseFireStore();

	const redirect = useNavigate();

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			setIsLoading(true);
			if (isLogin && !isReset) {
				setInputLogin(inputEmail);
				await signIn({ email: inputEmail, password: inputPassword });
				redirect("/profile");
			} else if (!isLogin) {
				await signUp({ email: inputEmail, password: inputPassword });
				createUserInfo({ email: inputEmail, login: inputLogin });
				redirect("/profile");
			} else if (isReset) {
				await resetPassword({ email: inputEmail });
				setIsReset((prevState) => !prevState);
			}
		} catch {
			console.error();
		}
		setIsLoading(false);
	}

	useEffect(() => {
		if (currentUser) {
			return redirect("/profile");
		}
	}, []);

	return (
		<section className="auth__left">
			<Link to={"/"} className="auth__logo">
				<img src="/assets/images/logo/logo.png" alt="Logo" className="auth__logo-img" />
				<h1 className="auth__logo-text">{process.env.REACT_APP_PROJECT_NAME}</h1>
			</Link>
			<form
				className="auth__form"
				method={"POST"}
				onSubmit={(e) => {
					handleSubmit(e)
						.then((r) => console.log(r))
						.catch((err) => console.log(err));
				}}
			>
				<label className="auth__form-text">{isLogin ? "Kirý" : "Tirkelý"}</label>
				<div className="auth__input-field" style={{ display: isLogin ? "none" : "flex" }}>
					<input
						type="text"
						placeholder={"Login"}
						className="auth__form-input"
						autoComplete={"off"}
						value={inputLogin}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setInputLogin(e.target.value);
						}}
					/>
					<img
						src="/assets/icons/user-2.svg"
						draggable={false}
						alt="user"
						className="auth__input-field-image"
					/>
				</div>
				<div className="auth__input-field ">
					<input
						type="email"
						placeholder={"Poshta"}
						className="auth__form-input"
						autoComplete={"off"}
						value={inputEmail}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setInputEmail(e.target.value);
						}}
						required={true}
					/>
					<img
						src="/assets/icons/email.svg"
						draggable={false}
						alt="email"
						className="auth__input-field-image"
					/>
				</div>
				<div className="auth__input-field" style={{ display: isReset ? "none" : "flex" }}>
					<input
						type={showPass ? "password" : "text"}
						placeholder={"Qupıa sóz"}
						className="auth__form-input"
						autoComplete={"off"}
						value={inputPassword}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setInputPassword(e.target.value);
						}}
					/>
					<img
						src={`/assets/icons/eye-${showPass}.svg`}
						draggable={false}
						alt="eye"
						className="auth__input-field-image"
						onClick={() => {
							setShowPass((prevState) => !prevState);
						}}
					/>
				</div>
				<div
					className="auth__input-field"
					onClick={() => {
						setRememberTick((prevState) => !prevState);
					}}
					style={{ width: "fit-content" }}
				>
					<img
						src={`/assets/icons/check-${rememberTick}.svg`}
						alt="checkbox"
						className="auth__radio"
						draggable={false}
					/>
					<label className="auth__form-radio-text">Este saqtaý</label>
				</div>
				<button className="auth__form-button" type={"submit"} disabled={isLoading}>
					{isLogin ? "Kirý" : "Tirkelý"}
				</button>
				<div className="auth__form-actions">
					<button
						className="auth__form-action"
						onClick={(e) => {
							e.preventDefault();
							setIsLogin((prevState) => !prevState);
						}}
					>
						{isLogin ? "Create account" : "Already have an account"}
					</button>
					<button
						className="auth__form-action"
						style={{ display: isLogin ? "block" : "none" }}
						onClick={(e) => {
							e.preventDefault();
							setIsReset((prevState) => !prevState);
						}}
					>
						{isReset ? "Esime tústi" : "Qupıa sóz esimde joq"}
					</button>
				</div>
			</form>
		</section>
	);
}
