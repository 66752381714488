import React from "react";
import { useParams } from "react-router-dom";

import { motion } from "framer-motion";
import { defaultAnimation } from "../../App";

import Header from "../../components/header/Header";
import Hero from "../../components/Hero";
import ChapterContent from "./components/ChapterContent";

export default function Chapter() {
	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero title={useParams().title} description={"Taraý: " + useParams().chapter} />
			<ChapterContent />
		</motion.div>
	);
}
