import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebaseAuth } from "../../context/FirebaseAuthContext";
import { motion } from "framer-motion";
import { defaultAnimation } from "../../App";

import Header from "../../components/header/Header";
import ProfileContent from "./components/ProfileContent";
import Hero from "../../components/Hero";

export default function Profile() {
	const { currentUser } = useFirebaseAuth();
	const redirect = useNavigate();

	useEffect(() => {
		if (!currentUser) {
			// redirect("/auth");
		}
	}, []);

	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero />
			<ProfileContent />
		</motion.div>
	);
}
