import React from "react";
import { motion } from "framer-motion";

import Header from "../../components/header/Header";
import Hero from "../../components/Hero";
import { defaultAnimation } from "../../App";

export default function Home() {
	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero title={"Qazaqsha Manga saıty"} description={"Keıin jazam"} />
		</motion.div>
	);
}
