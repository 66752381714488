import React from "react";
import { motion } from "framer-motion";
import { defaultAnimation } from "../../App";

import "../../assets/styles/titles.scss";

import Header from "../../components/header/Header";
import Hero from "../../components/Hero";
import TitleContent from "./components/TitleContent";
import Comments from "../../components/Comments";

export default function Title() {
	return (
		<motion.div
			initial={defaultAnimation.initial}
			animate={defaultAnimation.animate}
			exit={defaultAnimation.exit}
			transition={defaultAnimation.transition}
		>
			<Header />
			<Hero />
			<TitleContent />
			<Comments />
		</motion.div>
	);
}
